import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { CommonServices } from "../../services/commonservice";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
// import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PipeTransform } from '@angular/core';
// import { ListItem } from './multiselect.model';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [CommonServices]
})
export class ReportsComponent implements OnInit {

  onSubmitLoading: boolean;
  auditInfo: any = [];
  text: any;
  noData: any;
  locations: any = [];
  dropdownList = [];
  selectedItems = [];
  selectedSubLocItems = [];
  dropdownSettings = {};
  dropdownSubList = [];
  subLocations: any;
  FromDate: any;
  ToDate: any;
  index: any;
  dateRangeFilter: boolean = false;
  filterData: any = [];
  reportsData: any;
  constructor(
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    // public date1: Date = new Date("2000-01-01"),
    // public date2: Date = new Date()
  ) { }
  key: string = "name"; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }
  p: number = 1;

  ngOnInit() {
    this.dropdownList = [this.locations];
    this.selectedItems = [];
    // this.selectedItems2 = [];
    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
    };
    // onItemSelect(item: any) {
    //   console.log(item);
    // }
    // onSelectAll(items: any) {
    //   console.log(items);
    // };
    this.spinnerService.show();
    this.getAllClientAuditinfo();
    this.getLocations();
  }
  onChangeLoc($event) {
    this.selectedSubLocItems = []
    this.selectedItems = $event;
    this.getSubLocations();
    // for (let list of this.dropdownSubList) {
    //   debugger
    //   for (let items of this.selectedSubLocItems) {
    //     if (items.item_text == list.item_text) {
    //       debugger
    //       this.selectedSubLocItems.push(list)
    //     }
    //   }
    // }
  }
  onChangeSubLoc($event) {
    this.selectedSubLocItems = $event
  }
  filteredData() {
    if (this.FromDate > this.ToDate) {
      alert("Please ensure that To Date is greater than or equal to the From Date.");
      this.ToDate = "";
      return false
    }
    if (this.FromDate && this.ToDate) {
      var FMonth = this.FromDate.getMonth() + 1
      function getFromMonthFormat(FMonth) {
        return (FMonth < 10) ? '0' + FMonth : '' + FMonth;
      }
      var FDate = this.FromDate.getDate()
      function getFromDateFormat(FDate) {
        return (FDate < 10) ? '0' + FDate : '' + FDate;
      }
      var FHour = this.FromDate.getHours()
      function getFromHours(FHour) {
        return (FHour < 10) ? '0' + FHour : '' + FHour;
      }
      var FMinute = this.FromDate.getMinutes()
      function getFromMints(FMinute) {
        return (FMinute < 10) ? '0' + FMinute : '' + FMinute;
      }
      var FromMnth = getFromMonthFormat(FMonth);
      var FromDt = getFromDateFormat(FDate);
      var FromHrs = getFromHours(FHour);
      var FromMints = getFromMints(FMinute);
      var FromDate = this.FromDate.getFullYear() + "-" + FromMnth + "-" + FromDt;
      var FromTime = FromHrs + ":" + FromMints;
      var FromDateTime = FromDate + " " + FromTime;
      // TO Date
      var TMonth = this.ToDate.getMonth() + 1
      function getToMonthFormat(TMonth) {
        return (TMonth < 10) ? '0' + TMonth : '' + TMonth;
      }
      var TDate = this.ToDate.getDate()
      function getToDateFormat(TDate) {
        return (TDate < 10) ? '0' + TDate : '' + TDate;
      }
      var ToHour = this.ToDate.getHours()
      function getToHours(ToHour) {
        return (ToHour < 10) ? '0' + ToHour : '' + ToHour;
      }
      var ToMinute = this.ToDate.getMinutes()
      function getToMints(ToMinute) {
        return (ToMinute < 10) ? '0' + ToMinute : '' + ToMinute;
      }
      var ToMnth = getToMonthFormat(TMonth);
      var ToDt = getToDateFormat(TDate);
      var ToHrs = getToHours(ToHour);
      var ToMints = getToMints(ToMinute);
      var ToDate = this.ToDate.getFullYear() + "-" + ToMnth + "-" + ToDt;
      var ToTime = ToHrs + ":" + ToMints;
      var ToDateTime = ToDate + " " + ToTime;
      // var timestamp = this.reportsData[1].eventTimestamp.replace('T', ' ').split('.')[0]
      // var timestamp2 = this.reportsData[1].eventTimestamp.substring(0, 16);
      // for (let list of this.reportsData) {
      //   var eventTimestamp = list.eventTimestamp.substring(0, 16);
      //   eventTimestamp = eventTimestamp.replace('T', ' ')
      //   list.eventTimestamp = eventTimestamp;
      // }
      // console.log(this.reportsData)
      this.filterData = this.reportsData.filter(
        obj => obj.eventTimestamp >= FromDateTime && obj.eventTimestamp <= ToDateTime
      );
      // obj.eventTimestamp.slice(0, 10) <= this.ToDate
      this.auditInfo = this.filterData;
      if (this.auditInfo.length == 0) {
        this.noData = "No Data Found!"
        this.p = 1;
      }
      // console.log(this.auditInfo);
    } else {
      this.auditInfo = this.reportsData;
      if (this.auditInfo.length == 0) {
        this.noData = "No Data Found!"
        this.p = 1;
      }
    }
    if (this.selectedItems.length !== 0) {
      let info = [];
      for (let list of this.auditInfo) {
        for (let items of this.selectedItems) {
          if (items == list.locationName) {
            if (this.selectedSubLocItems.length !== 0) {
              for (let items of this.selectedSubLocItems) {
                if (items.item_text == list.subLocationName) {
                  info.push(list)
                  // subLoc.push(list)
                }
              }
            } else {
              info.push(list)
            }
          }
        }
      }
      // console.log(info);
      this.auditInfo = info;
      if (this.auditInfo.length == 0) {
        this.noData = "No Data Found!"
        this.p = 1;
      }
      // for (const list of this.selectedItems) {
      //   debugger;
      //   this.filterData = this.reportsData.filter(
      //     obj => obj.locationName == list,
      //   );
      //   info.push(this.filterData);
      //   // info = [...this.filterData, ...this.filterData];
      //   this.auditInfo = info;
      //   console.log(this.auditInfo)
      // }
    }
    // else {
    //   this.auditInfo = this.reportsData;
    // }
    // if (this.dropdownSubList.length !== 0) {
    //   debugger;
    //   let subLoc = [];
    //   for (let list of this.reportsData) {
    //     for (let items of this.dropdownSubList) {
    //       if (items.item_text == list.subLocationName) {
    //         debugger;
    //         subLoc.push(list)
    //         // this.orders.splice(this.orders.indexOf(order), 1);
    //       }
    //     }
    //   }
    //   console.log(subLoc);
    //   this.auditInfo = subLoc;
    // } else {
    //   this.auditInfo = this.reportsData;
    // }
  }
  getAllClientAuditinfo() {
    if (this.dateRangeFilter) {
      this.auditInfo = this.filterData;
    } else {
      this.spinnerService.show();
      let token = localStorage.getItem("zerv");
      this.CommonServices.getAllClientAuditinfo(token).subscribe(
        res => {
          if (res.code == 200) {
            this.auditInfo = res.listUserAudit;
            for (let list of this.auditInfo) {
              var eventTimestamp = list.eventTimestamp.substring(0, 16);
              eventTimestamp = eventTimestamp.replace('T', ' ')
              list.eventTimestamp = eventTimestamp;
            }
            this.reportsData = this.auditInfo;
            // this.auditInfo = this.auditInfo.filter(
            //   for(const list of this.selectedItems){
            //   obj => obj.eventTimestamp.slice(0, 10) >= this.FromDate && obj.eventTimestamp.slice(0, 10) <= this.ToDate &&
            //      if(obj.locationName == list.locationName){
            //      this.selectedItems == list.locationName;
            //     }
            //    } 
            //   //  obj.locationName == this.selectedItems
            // );
            if (this.auditInfo.length == 0) {
              this.noData = "No Data Found!"
              this.p = 1;
            }
            this.spinnerService.hide();
            this.onSubmitLoading = false;
          } else {
            this.onSubmitLoading = false;
          }
        },
        err => {
          // console.log(err);
          this.onSubmitLoading = false;
          this.spinnerService.hide();
          this.text = "No Data Found";
        }
      );
    }
  }
  getLocations() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getLocation(token).subscribe(
      res => {
        if (res.code == 200) {
          this.locations = res.listLocations;
        }
      },
      err => {
        // console.log(err);
      }
    );
  };
  // getSubLocations(ev) {
  //   // this.spinnerService.show();
  //   // this.subLocations[i] = [];
  //   // if (!onEdit) {
  //   //   this.clearAccessPoint(i);
  //   // }
  //   let token = localStorage.getItem("zerv");
  //   this.CommonServices.getSubLocation(token, ev).subscribe(
  //     res => {
  //       if (res.code == 200) {
  //         this.subLocations = res.listSublocations;
  //         // this.subLocations[i] = res.listSublocations;
  //         let subLoc = [];
  //         for (let i = 0; i < this.subLocations.length; i++) {
  //           subLoc.push({ item_id: i, item_text: this.subLocations[i].name });
  //         }
  //         this.dropdownSubList = subLoc;
  //         // this.selectedItems2 = [];
  //         // this.spinnerService.hide();
  //       }
  //     },
  //     err => {
  //       // this.spinnerService.hide();
  //       console.log(err);
  //     }
  //   );
  // };
  getSubLocations() {
    let locName = [];
    this.selectedItems.forEach(loc => {
      let lName = {};
      lName['location'] = loc;
      locName.push(lName);
    });
    let obj = {
      "listLocations": locName
    };
    let token = localStorage.getItem("zerv");
    this.CommonServices.multipleSubLocations(token, obj).subscribe(
      res => {
        if (res.code == 200) {
          this.subLocations = res.listSublocations;
          // this.subLocations[i] = res.listSublocations;
          let subLoc = [];
          for (let i = 0; i < this.subLocations.length; i++) {
            subLoc.push({ item_id: i, item_text: this.subLocations[i].name });
          }
          this.dropdownSubList = subLoc;
          // this.selectedItems2 = [];
          // this.spinnerService.hide();
        }
      },
      err => {
        // this.spinnerService.hide();
        // console.log(err);
      }
    );
  };
}
