import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { CommonServices } from "../../services/commonservice";
import { sample } from "rxjs/operators";
import _ from "lodash";

@Component({
  selector: "app-device-zerv-admin",
  templateUrl: "./device-zerv-admin.component.html",
  styleUrls: ["./device-zerv-admin.component.css"],
  providers: [CommonServices]
})
export class DeviceZervAdminComponent implements OnInit {
  form: FormGroup;
  deviceForm: FormGroup;
  devices: any = [];
  deviceById: any = {};
  display = "none";
  devicesList: any = [];
  devicesData: any = {};
  customerId: any;
  customers: any;
  associate: boolean = false;
  unassociate: boolean = false;
  onSubmitLoading: boolean;
  submitted = false;
  itemrow: any = {};
  deviceId: any;
  active: any;
  access: any;
  idDevice: any;
  editDeviceData: any;
  noData: any;
  listNonCustomerDevice: any = [];
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }
  //sorting
  key: string = "name"; //set default
  reverse: boolean = false;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing p to one
  p: number = 1;
  ngOnInit() {
    this.form = this.fb.group({
      deviceMACId: ["", Validators.required],
      deviceKey: ["", Validators.required],
      firmwareVersion: ["", Validators.required],
      deviceManufacturer: ["", Validators.required],
      assosiatedNFCTagUid: ["", Validators.required]
    });
    this.spinnerService.show();
    this.getDevices();
    this.getCustomer();
    this.addValidator();
  }
  clear() {
    this.form.reset();
  }
  addValidator() {
    this.form.controls["deviceMACId"].setAsyncValidators([
      this.ValidDeviceNotInList()
    ]);
    this.form.controls["assosiatedNFCTagUid"].setAsyncValidators([
      this.NFCtagUidNotInList()
    ]);
  }

  ValidDeviceNotInList(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let bReturn: boolean = true;
      (this.devices || []).filter(items => {
        if (
          this.form.controls["deviceMACId"].value.toLowerCase() ==
          items.deviceMACId.toLowerCase()) {
          bReturn = false;
        }
      });
      let err: ValidationErrors = { exists: true };
      return bReturn ? of(null) : of(err);
    };
  }
  NFCtagUidNotInList(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let aReturn: boolean = true;
      (this.devices || []).filter(items => {
        if (
          this.form.controls["assosiatedNFCTagUid"].value ==
          items.assosiatedNFCTagUid) {
          aReturn = false;
        }
      });
      let err: ValidationErrors = { exists: true };
      return aReturn ? of(null) : of(err);
    };
  }

  get f() {
    return this.form.controls;
  }
  openModalDialog() {
    this.display = "block"; //Set block css
  }

  closeModalDialog() {
    this.display = "none"; //set none css after close dialog
  }
  customerChange(e) {
    this.customerId = e.target.value;
  }

  onChange(customerName, deviceKey, e) {
    let checked = e.target.checked;
    if (checked == true && customerName == "") {
      this.associate = true;
      this.unassociate = false;
      this.devicesList.push(deviceKey);
      // console.log(this.devicesList);
    }
    if (checked == true && customerName != "") {
      this.unassociate = true;
      this.associate = false;
      this.devicesList.push(deviceKey);
      // console.log(this.devicesList);
    }
    if (checked == false && customerName == "") {
      this.associate = false;
      this.devicesList = _.filter(this.devicesList, a => {
        if (a != deviceKey) {
          return a;
        }
      });
      // console.log(this.devicesList);
    }
    if (checked == false && customerName != "") {
      this.unassociate = false;
      this.devicesList = _.filter(this.devicesList, a => {
        if (a != deviceKey) {
          return a;
        }
      });
      // console.log(this.devicesList);
    }
  }

  getCustomer() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getCustomer(token).subscribe(
      res => {
        // if (res.code == 200) {
        this.customers = res;
        this.spinnerService.hide();
        this.onSubmitLoading = false;
        // } else {
        //   this.onSubmitLoading = false;
        // }
      },
      err => {
        console.log(err);
        this.onSubmitLoading = false;
      }
    );
  }
  getDevices() {
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.CommonServices.getDevices(token).subscribe(
      res => {
        if (res.code == 200) {
          this.devices = res.listGetAllDevices;
          if (this.devices.length == 0) {
            this.noData = "No Data Found!";
          }
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  getDeviceById(Id,Modal) {
    this.deviceId = Id;
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.CommonServices.getDeviceById(token, Id).subscribe(
      res => {

        if (res.code == 200) {
          this.deviceById = res;
          this.devicesData["deviceMACId"] = this.deviceById.deviceMACId;
          this.devicesData["deviceKey"] = this.deviceById.deviceKey;
          this.devicesData["firmwareVersion"] = this.deviceById.firmwareVersion;
          this.devicesData[
            "assosiatedNFCTagUid"
          ] = this.deviceById.assosiatedNFCTagUid;
          this.devicesData["deviceManufacturer"] = this.deviceById.deviceManufacturer;
          this.idDevice = this.deviceById.id;
          // this.getDevices();
          this.spinnerService.hide();
          Modal.show();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
          this.spinnerService.hide();
          Modal.show()
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
        Modal.show();
        this.onSubmitLoading = false;
      }
    );
  }
  postZervDevices(addDeviceModal) {
    this.submitted = true;
    let formData = this.devicesData;
    let data: any;
    data = {
      listAddDevice: [
        {
          deviceMACId: formData.deviceMACId,
          deviceKey: formData.deviceKey,
          firmwareVersion: formData.firmwareVersion,
          deviceManufacturer: formData.deviceManufacturer,
          assosiatedNFCTagUid: formData.assosiatedNFCTagUid
        }
      ]
    };
    let token = localStorage.getItem("zerv");
    this.CommonServices.postDevice(token, data).subscribe(
      res => {
        if (res.code == 200) {
          this.form.reset();
          addDeviceModal.hide();
          this.getDevices();
          this.onSubmitLoading = false;
        } else {
          this.form.reset();
          this.onSubmitLoading = false;
          this.getDevices();
        }
      },
      err => {
        console.log(err);
        this.onSubmitLoading = false;
      }
    );
    console.log(data);

  }
  associateDevices() {
    let data: any;
    data = {
      listDevices: this.devicesList,
      customerId: this.customerId
    };
    // console.log(this.customerId);
    let token = localStorage.getItem("zerv");
    this.CommonServices.associate(token, data).subscribe(
      res => {
        if (res.code == 200) {
          this.customerId = "";
          this.getDevices();
          this.closeModalDialog();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        console.log(err);
        this.onSubmitLoading = false;
      }
    );
  }
  getSwitch(Id, access) {
    this.deviceId = Id;
    this.access = access;
  }
  postSwitchDevice() {
    let obj = {
      id: this.deviceId,
      active: this.active
    };
    let token = localStorage.getItem("zerv");
    this.CommonServices.postSwitchDevice(token, obj).subscribe(
      res => {

        if (res.code == 200) {
          this.getDevices();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        this.onSubmitLoading = false;
      }
    );
  }
  onChangeSwitch(e) {
    this.active = e.target.checked;
  }
  no() {
    this.getDevices();
  }
  updateZervDevices(Id) {
    this.deviceId = Id;
    this.submitted = true;
    let formData = this.devicesData;
    let obj = {
      // id: this.idDevice,
      deviceMACId: formData.deviceMACId,
      deviceKey: formData.deviceKey,
      firmwareVersion: formData.firmwareVersion,
      deviceManufacturer: formData.deviceManufacturer,
      assosiatedNFCTagUid: formData.assosiatedNFCTagUid,
      active: true

    };
    let token = localStorage.getItem("zerv");
    this.CommonServices.updateDevice(token, obj, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.form.reset();
          this.getDevices();
          this.onSubmitLoading = false;
        } else {
          this.form.reset();
          this.onSubmitLoading = false;
        }
      },
      err => {
        console.log(err);
        this.onSubmitLoading = false;
      }
    );
  }
  getSafeTrustDeviceSync(Modal) {
    this.onSubmitLoading = true;
    let token = localStorage.getItem("zerv");
    this.CommonServices.getSafeTrustDeviceSync(token).subscribe(
      res => {
        if (res.code == 200) {
          this.listNonCustomerDevice = res.listNonCustomerDevice
          this.getDevices();
          this.spinnerService.hide();
          this.onSubmitLoading = false;
          Modal.show()
          // this.CommonServices.showSuccess("Devices from safetrust synchronized successfully", "Devices List")
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
        this.CommonServices.showError("Devices from safetrust are not synchronized", "Devices List");
      }
    );
  }
}
