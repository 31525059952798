import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from 'aws-amplify';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // Amplify.configure({
  //   Auth: {
  //     region: 'US-EAST-1',
  //     userPoolId: 'us-east-1_4DfY3aAe0',
  //     userPoolWebClientId: '1fo41m5qc2bs6achjisq1c2hdr',
  //     mandatorySignIn: true,
  //   },
  //   API: {
  //   }
  // });
  Amplify.configure({
    Auth: {
      region: 'US-EAST-1',
      userPoolId: 'us-east-1_ONoy3nQ9d',
      userPoolWebClientId: '1u520vitom0j33ec163r36pht9',
      mandatorySignIn: true,
    },
    API: {
    }
  });

