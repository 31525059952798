import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonServices } from "../services/commonservice";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
@Component({
  selector: "app-confirm-forgot-pwd",
  templateUrl: "./confirm-forgot-pwd.component.html",
  styleUrls: ["./confirm-forgot-pwd.component.css"],
  providers: [CommonServices]
})
export class ConfirmForgotPwdComponent implements OnInit {
  public confirmForgotForm: FormGroup;
  error: string;
  submitted = false;
  data: any = {};
  email: any;
  confirmationCode: any;
  password: any;
  invalidMessage: any;
  success: any;
  public onSubmitLoading: boolean = false;
  constructor(
    private _fb: FormBuilder,
    public router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {

    this.confirmForgotForm = this._fb.group({
      email: ["", Validators.required],
      confirmationCode: ["", Validators.required],
      password: ["", Validators.required]
    });
    // console.log(this.email);
  }
  confirmForgotPwd() {
    this.onSubmitLoading = true;
    let obj = {
      username: this.data.email,
      confirmationCode: this.data.confirmationCode,
      password: this.data.password
    };
    this.CommonServices.confirmForgotPwd(obj).subscribe(
      res => {
        if (res.code == 200) {
          // this.success = "Password Updated Successfully";
          this.CommonServices.showSuccess("Password Updated Successfully", "Successfully Updated")
          this.confirmForgotForm.reset();
          this.onSubmitLoading = false;
          this.router.navigate(['/login']);
        } else {
          // this.invalidMessage = "Incorrect Credential";
          this.CommonServices.showError("Incorrect Credential", "Not Found")
          this.onSubmitLoading = false;
          this.confirmForgotForm.reset();
        }
      },
      err => {
        // console.log(err);
        // this.invalidMessage = "Incorrect Credential";
        this.CommonServices.showError("Incorrect Credential", "Not Found")
        this.onSubmitLoading = false;
      }
    );
  }
}
