import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors
} from "@angular/forms";
import { Observable, of } from "rxjs";
import { CommonServices } from "../../services/commonservice";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO
} from "ngx-intl-tel-input";
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: "app-customers",
  templateUrl: "./customers.component.html",
  styleUrls: ["./customers.component.css"],
  providers: [CommonServices]
})
export class CustomersComponent implements OnInit {
  form: FormGroup;
  message: any;
  isSuperAdmin: boolean = false;
  customersInfo: any = [];
  customerData: any = {};
  customersById: any = {};
  customerLocList: any = [];
  onSubmitLoading: boolean;
  separateDialCode = true;
  submitted = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  addmoreAdminData: any = {};
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  // selectMessage: boolean = false;
  messageModal: BsModalRef;
  invitationMessage: any;
  matiVerify: boolean = false;
  isChecked: boolean = false;
  welcomeTo: any = "Welcome to ";
  messageContent: any = ". You have been granted permission for mobile access to the organization's facilities. Please download the app from iOS:  OR   Android:  "
  custId: any;
  showAddmoreAdminModal:boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private modalServices: BsModalService
  ) { }
  //sorting
  key: string = "name"; //set default
  reverse: boolean = false;
  NocustomersInfo: any;
  NocustomerLocList: any;
  customer: any;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  //initializing p to one
  p: number = 1;

  ngOnInit() {
    this.isChecked = false
    this.form = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      customerName: ["", Validators.required],
      address: ["", Validators.required],
      phoneNumber: ["", [Validators.minLength(10), Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      temporaryPassword: ["", [Validators.minLength(6), Validators.required]],
      confirmPassword: ["", Validators.required],
    });
    this.spinnerService.show();
    this.getCustomer();
    this.addValidator();
    this.matchpassword();
  }
  onChange(e) {

    let checked = e.target.checked;
    this.isSuperAdmin = checked;
  }
  clear() {
    this.form.reset();
    this.isChecked = false
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  addValidator() {

    this.form.controls["customerName"].setAsyncValidators([
      this.CustomerNotInList()
    ]);
  }
  matchpassword() {

    this.form.controls["confirmPassword"].setAsyncValidators([
      this.confirmPassword()
    ]);
  }
  CustomerNotInList(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let bReturn: boolean = true;
      (this.customersInfo || []).filter(items => {
        if (
          this.form.controls["customerName"].value.toLowerCase() ==
          items.customerName.toLowerCase()
        ) {
          bReturn = false;
        }
      });
      let err: ValidationErrors = { exists: true };
      return bReturn ? of(null) : of(err);
    };
  }
  getCustomer() {
    let token = localStorage.getItem("zerv");
    this.CommonServices.getCustomer(token).subscribe(
      res => {
        this.customersInfo = res;
        if (this.customersInfo.length == 0) {
          this.NocustomersInfo = "No Data Found!"
        }
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }
  verifyMati($event){
    this.matiVerify = $event.target.checked;
  }
  postCustomer() {
    this.submitted = true;
    // if (this.form.invalid) {
    //   return;
    // }
    let formData = this.customerData;
    let phoneNumber =
      formData.phoneNumber.dialCode + formData.phoneNumber.number;
    phoneNumber = phoneNumber.replace(/[- )(]/g, "");
    let token = localStorage.getItem("zerv");
    let obj = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      customerName: formData.customerName,
      address: formData.address,
      phoneNumber: phoneNumber,
      email: formData.email,
      allowMatiVerification: this.matiVerify,
      temporaryPassword: formData.temporaryPassword,
      isSuperAdmin: this.isSuperAdmin,
      invitationMessage: this.invitationMessage,
      sendInvite: this.isChecked
    };
    // console.log(obj);
    this.CommonServices.postCustomer(token, obj).subscribe(
      res => {
        // if (res.code == 200) {
        this.form.reset();
        this.CommonServices.showSuccess("Customer Added Successfully", "Customer Added ")
        this.getCustomer();
        this.onSubmitLoading = false;
        // } else {
        //   this.form.reset();
        //   this.onSubmitLoading = false;
        // }
      },
      err => {
        this.onSubmitLoading = false;
      }
    );
  }
  confirmPassword(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let bReturn: boolean = true;
      if (
        this.form.controls["confirmPassword"].value !=
        this.form.controls["temporaryPassword"].value
      ) {
        bReturn = false;
      }
      let err: ValidationErrors = { exists: true };
      return bReturn ? of(null) : of(err);
    };
  }
  getCustomerById(Id,Modal) {
    this.custId = Id;
    this.customersById = "";
    this.customerLocList = "";
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.CommonServices.getCustomerById(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.customersById = res;
          this.customerLocList = res.listCustomerDevices;
          if (this.customerLocList.length == 0) {
            this.NocustomerLocList = "No Data Found!"
          }
          Modal.show();
          this.spinnerService.hide();
          this.customerData["address"] = this.customersById.customerAddress;
          this.isChecked = this.customersById.sendInvite;
          this.matiVerify = this.customersById.allowMatiVerification;
          this.invitationMessage = this.customersById.invitationMessage;
          this.onSubmitLoading = false;
        } else {
          this.spinnerService.hide();
          Modal.show();
          this.onSubmitLoading = false;
          this.customersById = "";
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.customersById = "";
        this.isChecked = false;
        this.invitationMessage = "";
        this.onSubmitLoading = false;
      }
    );
  }
  closeModal() {
    this.customersById = "";
    this.customerLocList = "";
  }
  showMessageModal(InviteMessage: TemplateRef<any>) {
    this.messageModal = this.modalServices.show(InviteMessage, { class: 'modal-md', backdrop: 'static', keyboard: false });
  }
  closeMessageModal(event) {
    this.invitationMessage = null;
    // this.selectMessage = false;
    this.isChecked = false;
    this.messageModal.hide();
  }
  checkMessage($event, InviteMessage) {
    this.isChecked = $event.target.checked;
    if ($event.target.checked) {
      this.showMessageModal(InviteMessage)
      this.invitationMessage = this.welcomeTo + this.customerData.customerName + this.messageContent
    } else {
      this.invitationMessage = null
    }
  }
  updateCheckMessage($event, InviteMessage) {
    this.isChecked = $event.target.checked;
    if ($event.target.checked) {
      this.showMessageModal(InviteMessage)
      this.invitationMessage = this.customersById.invitationMessage;
    } else {
      this.invitationMessage = null
    }
  }
  updateCustomerInfo(Id) {
    let token = localStorage.getItem("zerv");
    let obj = {
      customerAddress: this.customerData.address,
      invitationMessage: this.invitationMessage,
      sendInvite: this.isChecked,
      allowMatiVerification: this.matiVerify
    };
    // console.log(obj);
    this.CommonServices.updateCustomerInfo(token, obj, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.customerData.address = "";
          this.invitationMessage = "";
          this.isChecked = false;
          this.CommonServices.showSuccess("Customer Added Successfully", "Customer Added ")
          this.getCustomer();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        this.CommonServices.showError("Customer Data was not Updated Successfully", "Try Again")
        this.onSubmitLoading = false;
      }
    );
  }
  addmoreAdminModal(customer) {
    this.customer = customer;
    this.showAddmoreAdminModal = true;
  }
  onCloseAdminModal() {
    this.showAddmoreAdminModal = false;
  }
}
