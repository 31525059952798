import { Component, OnInit } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonServices } from "../../services/commonservice";
import _ from "lodash";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
  providers: [CommonServices]
})
export class UsersComponent implements OnInit {
  userData: any;
  onSubmitLoading: boolean;
  showCreateUserModal: boolean;
  user: any;
  showEditUserModal: boolean;
  showViewUserModal: boolean;
  active: any;
  userPhone: any;
  access: any;
  phoneId: any;
  // switchCase: any;
  userId: any = {};
  userDetails: any;
  NoUserData: any;
  constructor(
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) {
  }

  ngOnInit() {
    this.getUsersList();
  }

  openCreateUserModal() {
    this.userDetails = this.userData
    this.showCreateUserModal = true
  }

  onSubmit(ev) {
    if (ev) {
      this.getUsersList();
    }
    this.showCreateUserModal = false;
    this.showEditUserModal = false;
  }

  getUsersList() {
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.CommonServices.getUser(token).subscribe(
      res => {
        if (res.code == 200) {
          this.userData = res.listUsers;
          if (this.userData.length == 0) {
            this.NoUserData = "No Data Found!"
          }
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
        this.onSubmitLoading = false;
      }
    );
  }

  onEditUser(user) {
    this.user = user;
    this.showEditUserModal = true;
  }

  viewUserModal(user) {
    this.user = user;
    this.showViewUserModal = true;
  }

  onCloseViewUserModal() {
    this.showViewUserModal = false;
  }

  // kiran
  onChangeSwitch(e) {

    this.active = e.target.checked;
  }
  getphoneId(id, access) {

    this.phoneId = id;
    this.access = access;
    // this.switchCase = "user";
  }
  userActive_Inactive() {

    let obj = {
      id: this.phoneId,
      active: this.active
    };
    let token = localStorage.getItem("zerv");
    this.CommonServices.userActive_Inactive(token, obj).subscribe(
      res => {

        if (res.code == 200) {
          this.onSubmitLoading = false;
          this.getUsersList();
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        this.onSubmitLoading = false;
      }
    );
  }
  no() {
    this.getUsersList();
  }
  DeleteUser(Id) {
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.CommonServices.DeleteUser(token, Id).subscribe(
      res => {
        if (res.code == 200) {
          this.spinnerService.hide();
          this.getUsersList();
          this.CommonServices.showSuccess("User Deleted from Database successfully", "User Deleted")
          // this.onSubmitLoading = false;
        } else {
          this.spinnerService.hide();
          this.CommonServices.showError("User was not Deleted from Database", "Try Again")
          // this.onSubmitLoading = false;
        }
      },
      error => {
        // console.log(error);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
}
