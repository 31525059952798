import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonConfigService {

  baseUrl = environment.API_URL;
  apiUrls = {
    login: '/v1/portal/login',
    getCustomers: '/v1/portal/customer',
  };
  private urlGetDashboardCounts =
    this.baseUrl + "v1/portal/dashboard/getoverview";

  constructor(private http: Http) {
  }

  public getApiUrl(key: any) {
    return this.baseUrl + this.apiUrls[key];
  }

  getServiceUrl(service: string): string {
    return this.getApiUrl(service);
  }
}