import { Injectable } from '@angular/core';
// import { MessageService } from 'primeng/api';
import { CommonConfigService } from './common-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  loading: any = [];
  // private urlGetDashboardCounts =
  // environment.baseUrl + "v1/portal/dashboard/getoverview";

  constructor(
    private commonConfigService: CommonConfigService) { }

  showSuccessMessage(msg): void {
    console.log('success message service')
    // this.messageService.clear();
    // this.messageService.add({severity: 'success', detail:msg});
  }
  showErrorMessage(err): void {
    console.log('error message service')

    // this.messageService.add({severity:'error', detail:err.message});
  }

  getApiUrl(serviceName) {
    return this.commonConfigService.getServiceUrl(serviceName);
  }


  copyObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  setCookie(cname, cvalue, exdays?: any) {
    document.cookie = cname + '=' + cvalue + ';' + 'expires=' + exdays + ';path=/';
  }

  getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookie(cookie) {
    const user = this.getCookie(cookie);
    if (user !== '') {
      return true;
    } else {
      return false;
    }
  }

  setLoading(type, val) {
    if (type) {
      this.loading[type] = val;
    }
  }

}
