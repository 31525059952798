import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { RouterModule } from "@angular/router";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { Ng2OrderModule } from "ng2-order-pipe";
import { NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { AmplifyService, AmplifyAngularModule } from 'aws-amplify-angular';

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./client-admin/dashboard/dashboard.component";
import { AppRoutingModule } from "./app-routing.module";
import { HeaderComponent } from "./layout/header/header.component";
import { Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";
import { AuthService } from "./auth/auth.service";
import { AppMaterialModule } from "./app-material/app-material.module";
import { UsersComponent } from "./client-admin/users/users.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { DevicesComponent } from "./client-admin/devices/devices.component";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { DashboardZervAdminComponent } from "./zerv-admin/dashboard-zerv-admin/dashboard-zerv-admin.component";
import { CustomersComponent } from "./zerv-admin/customers/customers.component";
import { DeviceZervAdminComponent } from "./zerv-admin/device-zerv-admin/device-zerv-admin.component";
import { UsersZervAdminComponent } from "./zerv-admin/users-zerv-admin/users-zerv-admin.component";
import { Sidemenu1Component } from "./layout/sidemenu1/sidemenu1.component";
import { Sidemenu2Component } from "./layout/sidemenu2/sidemenu2.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { SideBarComponent } from "./layout/side-bar/side-bar.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { ImageUploaderComponent } from "./client-admin/users/image-uploader/image-uploader.component";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { ConfirmForgotPwdComponent } from "./confirm-forgot-pwd/confirm-forgot-pwd.component";
import { CookieService } from "ngx-cookie-service";
import { AuditLogsComponent } from "./zerv-admin/audit-logs/audit-logs.component";
import { AuditLogsClientComponent } from "./client-admin/audit-logs-client/audit-logs-client.component";
import { CreateUserComponent } from './client-admin/users/create-user/create-user.component';
import { SearchPipe } from './filters/search.pipe';
import { PopoverModule } from "ngx-bootstrap/popover";
import { ViewUserComponent } from './client-admin/users/view-user/view-user.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ToastrModule } from 'ngx-toastr';
import { CommonService } from './services/common.service';
import { ReportsComponent } from './client-admin/reports/reports.component';
import { DateTimePickerModule } from 'ngx-datetime-picker';
// import { Ng2TableModule } from 'ng2-table/ng2-table';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CommonServices } from './services/commonservice';
import { AddmoreAdminComponent } from './zerv-admin/customers/addmore-admin/addmore-admin.component';
import { SyndicateUsersComponent } from './client-admin/syndicate-users/syndicate-users.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    UsersComponent,
    DevicesComponent,
    DashboardZervAdminComponent,
    CustomersComponent,
    DeviceZervAdminComponent,
    UsersZervAdminComponent,
    Sidemenu1Component,
    Sidemenu2Component,
    SideBarComponent,
    ImageUploaderComponent,
    ForgotPasswordComponent,
    // DoorsComponent,
    ConfirmForgotPwdComponent,
    AuditLogsComponent,
    AuditLogsClientComponent,
    CreateUserComponent,
    ViewUserComponent,
    SearchPipe,
    ReportsComponent,
    AddmoreAdminComponent,
    SyndicateUsersComponent,
  ],
  imports: [
    NgbModule,
    HttpClientModule,
    HttpModule,
    BrowserModule,
    NgxIntlTelInputModule,
    AutocompleteLibModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    AmplifyAngularModule,

    // Ng2TableModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    DateTimePickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgMultiSelectDropDownModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    // PaginationModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    // NgMultiSelectDropDownModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [AuthService, AuthGuard, CookieService, CommonService, CommonServices, AmplifyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
