import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonServices } from "../../services/commonservice";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
  providers: [CommonServices]
})
export class ForgotPasswordComponent implements OnInit {
  public forgotForm: FormGroup;
  error: string;
  submitted = false;
  data: any = {};
  username: any;
  message: any;
  public onSubmitLoading: boolean = false;
  constructor(
    private _fb: FormBuilder,
    // private authService: AuthService,
    private router: Router,
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.forgotForm = this._fb.group({
      username: ["", Validators.required]
    });
    // console.log(this.username);
  }
  forgotPwd() {
    this.onSubmitLoading = true;
    let obj = {
      username: this.data.username
    };
    this.CommonServices.forgotPwd(obj).subscribe(
      res => {

        if (res.code == 200) {
          this.forgotForm.reset();
          this.onSubmitLoading = false;
          this.router.navigate(["/confirmForgotPassword"]);
        } else {
          this.forgotForm.reset();
          this.onSubmitLoading = false;
          this.CommonServices.showError("User Name does not exists", "Not Found")
        }
      },
      err => {
        // console.log(err);
        // this.message = "User Name does not exist";
        this.CommonServices.showError("User Name does not exists", "Not Found")
        this.onSubmitLoading = false;
      }
    );
  }

  // reset() {

  // if (this.resetForm.value.password == this.resetForm.value.confirmpassword) {
  // commentOperation = this.RedBenchService.resetPassword(this.Id, this.model)
  // commentOperation.subscribe(
  // results => {
  //this.modelr = results;
  // console.log(this.modelr);
  // this.alertService.success('Registration successful Please verify Your Email for iu90uhjnmfvsigning In', true);
  // this.success = results;
  // this.router.navigate(["/login"]);
  // },
  // err => {
  // Log errors if any
  // this.error = JSON.parse(err._body).Message;
  // });
  //   } else {
  //     this.message = "Password Mismatch !";
  //   }
  // }
  //   isFieldInvalid(field: string) {
  //     return (
  //       (!this.form.get(field).valid && this.form.get(field).touched) ||
  //       (this.form.get(field).untouched && this.formSubmitAttempt)
  //     );
  //   }
}
