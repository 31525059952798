import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthGuard } from 'src/app/auth/auth.guard';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  type: any;
  currentUser: any;
  constructor(private router: Router, private authGuard: AuthGuard) {
    this.currentUser = this.authGuard.role;
  }

  ngOnInit() {
    // debugger;
    this.type = this.currentUser
  }

}
