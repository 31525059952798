import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, Input } from "@angular/core";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { CommonServices } from 'src/app/services/commonservice';
import { user } from '../user.model';

import _ from "lodash";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: "app-view-user",
  templateUrl: "./view-user.component.html",
  styleUrls: ["../users.component.css"],
})

export class ViewUserComponent implements OnInit {
  @Input() user: any = {};
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  userForm: user;
  locations: any;
  userData: any;
  active: any;
  @ViewChild('content',
    { static: true }
  ) content: ElementRef;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: false
  };
  onSubmitLoading: boolean;
  showCreateUserModal: boolean;
  showEditUserModal: boolean;
  showViewUserModal: boolean;
  data: any;
  userPhone: any;
  userId: any;
  access: any;
  NoAccessList: any;
  constructor(
    private CommonServices: CommonServices,
    private spinnerService: Ng4LoadingSpinnerService,
    private modalService: NgbModal,
    private commonServices: CommonServices,
    private modalServices: BsModalService
  ) {

  }
  ngOnInit() {
    if (this.user && this.user.phoneNumber) {
      this.getUserById(this.user.phoneNumber);
      this.modalService.open(this.content, { backdrop: 'static', size: 'xl', keyboard: false });
    }
  }

  getUserById(userId) {
    let custId = this.user.customerId;
    this.userPhone = userId;
    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.commonServices.getUserById(token, userId, custId).subscribe(
      res => {
        if (res.code == 200) {
          this.data = res;
          this.data["firstName"] = res.name.substr(
            0,
            res.name.indexOf(" ")
          );
          this.data["lastName"] = res.name.substr(
            res.name.indexOf(" ") + 1
          );
          this.data.phoneNumber = '+' + res.phoneNumber;
          this.spinnerService.hide();
          if (this.data.listGetUserAccess.length == 0) {
            this.NoAccessList = "No Data Found!"
          }
        } else {
          this.spinnerService.hide();
          this.data = {};
        }
      },
      err => {
        // console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  close() {
    this.modalService.dismissAll(this.content);
    this.onSubmit.emit(false);
  }
  // kiran
  getDeleteAddress(Id) {

    this.spinnerService.show();
    let token = localStorage.getItem("zerv");
    this.CommonServices.getDeleteAddress(token, Id).subscribe(
      res => {

        if (res.code == 200) {

          this.getUserById(this.userPhone);
          this.spinnerService.hide();
          this.onSubmitLoading = false;
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        // console.log(err);
        this.onSubmitLoading = false;
        this.spinnerService.hide();
      }
    );
  }
  // Switch location
  onChangeSwitch(e) {

    this.active = e.target.checked;
  }
  getuserId(id, access) {

    this.userId = id;
    this.access = access;
    // this.switchCase = "userAccess";
  }
  locationAccess() {

    let obj = {
      id: this.userId,
      active: this.active
    };
    let token = localStorage.getItem("zerv");
    this.CommonServices.locationAccess(token, obj).subscribe(
      res => {

        if (res.code == 200) {
          this.onSubmitLoading = false;
          this.getUserById(this.userPhone);
        } else {
          this.onSubmitLoading = false;
        }
      },
      err => {
        this.onSubmitLoading = false;
      }
    );
  }

  openModal(confirmationDelete: TemplateRef<any>) {
    this.modalRef = this.modalServices.show(confirmationDelete, { class: 'modal-sm', backdrop: 'static', keyboard: false });
  }

  openSwitchModal(ConfirmationSwitch: TemplateRef<any>) {
    this.modalRef = this.modalServices.show(ConfirmationSwitch, { class: 'modal-sm', backdrop: 'static', keyboard: false });
  }

  confirm(): void {
    this.modalRef.hide();
  }

  decline(): void {
    this.modalRef.hide();
    this.getUserById(this.userPhone);
  }
}